:root {
  --background-main: #f0f3fb;
  --accent: #fb923c;
  --listItemIcon: 30px;
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: var(--background-main);
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--accent);
  opacity: .7;
}

::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}